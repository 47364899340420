import React from "react";
import { home } from "../data/dummydata";
import Typewriter from "typewriter-effect";
import resume from "../../files/resume.pdf";

export const Hero = () => {
  return (
    <>
      <section className="hero">
        {home.map((val, i) => (
          <div className="heroContent">
            <h3 className="fontSize" data-aos="fade-right">
              {val.text}
            </h3>
            <h1>
              <Typewriter
                options={{
                  strings: [`${val.name}`, `${val.post}`, `${val.design}`],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h1>
            <p data-aos="fade-left">{val.desc}</p>
            <a
              href={resume}
              download="Example-PDF-document"
              target="_blank"
              rel="noreferrer"
            >
              <button className="primaryBtn" data-aos="fade-up-right">
                Download CV
              </button>
            </a>
          </div>
        ))}
      </section>
    </>
  );
};
