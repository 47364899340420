import {
  ViewInAr,
  PieChart,
  Code,
  BarChart,
  CloudOutlined,
  FavoriteBorder,
  Public,
  PersonOutlined,
  AddLocationAltOutlined,
  PhoneIphone,
  EmailOutlined,
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
  Storage,
  Javascript,
} from "@mui/icons-material";

export const navlink = [
  {
    url: "/",
    text: "Home",
  },
  {
    url: "/about",
    text: "About",
  },
  {
    url: "/services",
    text: "A bit more of me",
  },
  {
    url: "/portfolio",
    text: "Portfolio",
  },
  {
    url: "/testimonials",
    text: "My Stack",
  },
  {
    url: "/blog",
    text: "My Timeline",
  },
  {
    url: "/contact",
    text: "Contact",
  },
];
export const home = [
  {
    text: "HELLO I'M",
    name: "BRENT PURKS",
    post: "FULL STACK DEVELOPER",
    design: "",
    desc: "I am a talented and motivated Full-Stack Developer. Highly collaborative team member who values teaching and learning from peers. Consistently delivers exceptional and leading results.",
  },
];
export const about = [
  {
    cover: "./images/me.jpeg",
    desc: "Hey there, I'm Brent, a full stack developer with a love for creating awesome web applications. With three years of experience under my belt, I've already learned so much and am excited to continue growing in my career. When I'm not coding, you can find me playing music or diving into the latest video game release. I love how music and gaming allow me to explore my creativity and unwind after a long day of coding.",
    desc1:
      "In my work as a full stack developer, I enjoy tackling complex problems and finding elegant solutions. From crafting beautiful user interfaces to designing robust back-end systems, I'm always up for a challenge. My passion for coding stems from a desire to create things that have a positive impact on people's lives. Whether it's building an app that makes someone's day a little easier or a website that helps businesses grow, I love the sense of satisfaction that comes from knowing my work is making a difference. Thanks for taking the time to check out my portfolio, and I look forward to working with you!",
  },
];
export const services = [
  {
    id: 1,
    icon: <Javascript />,
    title: "Frontend Stack",
    desc: "Javascript, HTML, CSS, Tailwind.css, SCSS, React, React Native, Angular, Vue, Stripe Payment Integration",
  },
  {
    id: 2,
    icon: <Storage />,
    title: "Backend Stack",
    desc: "Node.js, PostgreSQL, Express.js, SQL, Stripe",
  },
  {
    id: 3,
    icon: <ViewInAr />,
    title: "Creative Design",
    desc: "With a background in Instructional Design prior to coding, I am proficient in best design practices for the user experience.",
  },
  {
    id: 4,
    icon: <PieChart />,
    title: "Front, back, and mobile",
    desc: "Along with many full stack web applications, i also develop mobile applications. My interest in developing apps inspired me to learn to code",
  },
  {
    id: 5,
    icon: <Code />,
    title: "Team Player",
    desc: "With my background in Corporate Training, I am used to working closely with my team to ensure our product is optimal.",
  },
  {
    id: 6,
    icon: <BarChart />,
    title: "Coachable",
    desc: "I strongly welcome feedback of all kinds, and apply feedback effectively in my work. I believe that good leaders listen!",
  },
];
export const project = [
  {
    id: 1,
    icon: <CloudOutlined />,
    num: "3",
    title: "YEARS EXPERIENCE",
  },
  {
    id: 2,
    icon: <FavoriteBorder />,
    num: "2",
    title: "STARTUPS WORKED AT",
  },
  {
    id: 3,
    icon: <Public />,
    num: "24",
    title: "PROJECTS CREATED",
  },
  {
    id: 4,
    icon: <PersonOutlined />,
    num: "4",
    title: "JS FRAMEWORKS",
  },
];
export const portfolio = [
  {
    id: 1,
    cover: "../images/port/landingPageNile.png",
    name: "Nile Marketplace",
    category: "web",
    title: "Fullstack E-Commerce Site",
    link1: "https://unrivaled-gaufre-e3e78d.netlify.app/",
    link2: "https://github.com/Central-Mountain-Nile/Nile-frontend-app",
  },
  {
    id: 2,
    cover: "../images/port/backendNile.png",
    name: "Nile Marketplace Backend",
    category: "web",
    title: "Fullstack E-Commerce Site",
    link1: "https://unrivaled-gaufre-e3e78d.netlify.app/",
    link2: "https://github.com/Central-Mountain-Nile/Nile-Backend",
  },
  {
    id: 3,
    cover: "../images/port/Untitled.png",
    name: "Ract Native Job Hunter App",
    category: "mobile",
    title: "Job Hunting mobile app",
    link2: "https://github.com/ziggyboy2001/job-hunter",
  },
  {
    id: 4,
    cover: "../images/port/fitnessTracker.png",
    name: "Fitness Tracker",
    category: "web",
    title: "Fulltstack Lifestyle App",
    link1: "https://fitnesstrac-kr2301.netlify.app",
    link2: "https://github.com/TheHighnote/Fitness-Trac.kr-Client",
  },
  {
    id: 5,
    cover: "../images/port/backendFitness.png",
    name: "Fitness Tracker Backend",
    category: "web",
    title: "Fulltstack Lifestyle App",
    link2: "https://github.com/ziggyboy2001/fitness-tracker",
  },
  {
    id: 6,
    cover: "../images/port/stripeScrnShot.png",
    name: "Ready to use Stripe payments",
    name2: "to integrate into your webapp",
    category: "web",
    title: "Standalone Stripe Payment",
    link2:
      "https://github.com/ziggyboy2001/Stripe-payment-integration-for-react",
  },
];
export const testimonials = [
  {
    id: 1,
    text: "My Frontend Stack",
    image: "./images/testimonials/JS.png",
    image2: "./images/testimonials/html.png",
    image3: "./images/testimonials/css.png",
    image4: "./images/testimonials/react.png",
  },
  {
    id: 2,
    text: "My Frameworks",
    image: "./images/testimonials/scss.png",
    image2: "./images/testimonials/angular.png",
    image3: "./images/testimonials/vue.png",
    image4: "./images/testimonials/wind.png",
  },
  {
    id: 3,
    text: "My Backend Stack and extras",
    image: "./images/testimonials/postgresql.png",
    image2: "./images/testimonials/node.png",
    image3: "./images/testimonials/stripe2.png",
    image4: "./images/testimonials/firebase.png",
  },
  {
    id: 3,
    text: "Other tools and technologies",
    image: "./images/testimonials/figma.png",
    image2: "./images/testimonials/AE.png",
    image3: "./images/testimonials/PS.png",
  },
];
export const blog = [
  {
    id: 1,
    title: "The Beginning",
    date: "July, 2020",
    author: "Date:",
    desc: "In the summer of 2020 I decided I wanted to learn to code, and quickly began grasping the core concepts. a year later I was fortunate to begin my first job in software engineering with a startup in Atlanta.",
    cover: "./images/blog/code2.png",
  },
  {
    id: 2,
    title: "UT Dallas",
    date: "April, 2023",
    author: "Date:",
    desc: "Although I had professional experience at two startups by this point, I knew I needed to refine my skills and certify my expertise on paper. I graduated from the coding bootcamp program at UT Dallas in April, 2023.",
    cover: "./images/blog/diploma.png",
  },
  {
    id: 3,
    title: "Solesctice",
    date: "March, 2023",
    author: "Date:",
    desc: "March of 2023 I began working with Solestice, a startup in Atlanta focussed on bridging the brick & mortar retail world with the digital world in a new way.",
    cover: "./images/blog/Mockup.jpg",
  },
];
export const contact = [
  {
    icon: <AddLocationAltOutlined />,
    text1: "Atlanta, Ga",
    text2: "USA",
  },
  {
    icon: <PhoneIphone />,
    text1: "(770) 203 9091",
  },
  {
    icon: <EmailOutlined />,
    text1: "brentpurks1@icloud.com",
    text2: "brentpurkswork@gmail.com",
  },
  {
    icon: <LinkedIn />,
    link: "https://www.linkedin.com/in/brent-purks/",
    linkLabel: "Click here for my LinkedIn",
  },
];
export const social = [
  {
    icon: <Facebook />,
  },
  {
    icon: <Twitter />,
  },
  {
    icon: <Instagram />,
  },
];
