import React, { useState } from "react";
import { Heading } from "../common/Heading";
import { contact } from "../data/dummydata";
import emailjs from "@emailjs/browser";
import check128 from "../data/images/check128.png";

export const Contact = () => {
  const [showMessage, setShowMessage] = useState(false);

  // const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_kv3npow",
        "template_tg9ezfm",
        document.getElementById("contact-form"), // Use id selector to get the form element
        "RA0fC_0HO3ISywvpH"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowMessage(true);
        },
        (error) => {
          alert("something went wrong");
        }
      );
  };

  return (
    <>
      {showMessage ? (
        <div id="success-message-wrapper">
          <div id="success-message">
            <h4>
              Thank you, <br /> I will get back to you as soon as possible! Have
              a wonderful day.
            </h4>
            <div className="successImage">
              <img src={check128} alt="" />
            </div>
          </div>
        </div>
      ) : (
        <div className="contact">
          <div className="container">
            <Heading title="Keep In Touch" />
            <div className="content flexsb">
              <div className="right">
                <form id="contact-form">
                  <div className="flex">
                    <input
                      type="text"
                      placeholder="Name"
                      data-aos="flip-left"
                      onSubmit={sendEmail}
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      data-aos="flip-right"
                      onSubmit={sendEmail}
                    />
                  </div>
                  <input
                    type="email"
                    placeholder="Subject"
                    data-aos="flip-up"
                    onSubmit={sendEmail}
                  />
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    data-aos="flip-down"
                    onSubmit={sendEmail}
                  ></textarea>
                  <button
                    className="sendBtn"
                    data-aos="zoom-in-up"
                    onClick={sendEmail}
                  >
                    Submit
                  </button>
                </form>
              </div>
              <div className="left">
                {contact.map((item) => (
                  <div className="box" data-aos="zoom-in">
                    <i>{item.icon}</i>
                    <p>{item.text1}</p>
                    <p>{item.text2}</p>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>{item.linkLabel}</p>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
