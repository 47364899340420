import React from "react";
import Slider from "react-slick";
import { testimonials } from "../data/dummydata";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Testimonials = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };
  return (
    <>
      <section className="testimonials hero">
        <div className="container">
          <Slider {...settings}>
            {testimonials.map((val) => (
              <div className="box">
                {/* <p data-aos="zoom-out-down">{val.text}</p> */}
                <div className="img" data-aos="zoom-out-right">
                  <img src={val.image} alt="" />
                  <img src={val.image2} alt="" />
                  <img src={val.image3} alt="" />
                  <img src={val.image4} alt="" />
                </div>
                <h3 data-aos="zoom-out-left">{val.name}</h3>
                <label data-aos="zoom-out">{val.post}</label>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
};
