import React from "react";
import { LinkedIn } from "@mui/icons-material";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <>
          <div className="footerIcons">
            {/* <Facebook />
            <Twitter /> */}
            <Link
              to="https://www.linkedin.com/in/brent-purks/"
              className=""
              target="_blank"
            >
              <LinkedIn />
            </Link>
          </div>
        </>

        <p data-aos="zoom-in">Thank you</p>
      </footer>
    </>
  );
};

export default Footer;
